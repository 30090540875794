const exampleQuery =
    ">example\n" +
    "AAACGTGTAGTTGTAAAGCTTTGGGGGTAGTTCTCTGACAGATAAGGAAG\n" +
    "AGGCATCACTCAGGCGTTTAGCTGAGCAGATTGCAGCATTAAAAGAGAGT\n" +
    "GGCAAAATAAACTAGTGGTCGTGCATGGAGGCGGCGCTTCACTGATGGTC\n" +
    "TGCTGGCATTGAAAAGTGGCCTGAGCTCGGGCGAATTAGCTGCGGGGTTG\n" +
    "AGGAGCACGTTAGAGTAGAGGCCGGAGAAGTAGCGACGAGGGACGCCCTA\n" +
    "GCTAGCTTAGGGGAACGGCTTGTTGCAGCGCTGCTGGCGGCGGGTCTCCC\n" +
    "TGCTGTAGGACTCAGCGCCGCTGCGTTAGATGCGACGGAGGCGGGCCGGG\n" +
    "ATGAAGGCAGCGACGGGAACGTCGAGTCCGTGGACGCAGAAGCAATTGAG\n" +
    "GAGTTTGGTCCCCGTCCTAACAGGATTTATCGGCTTAGACGAAGAAGGGG\n" +
    "AACTGGGAAGGGGATCTTCTGACACCATCGCTGCGTTACTCGCTGAAGCT\n" +
    "TTAGGCGCGGACAAACTCATAATACTGACCGACGTAGACGGCGTTTACGA\n" +
    "TGCCGACCCTAAAAAGGTCCCAGACGCGAGGCTCTTGCCAGAGATAAGTG\n" +
    "TGGACGAGGCCGAGGAAAGCGCCTCCGAATTAGCGACCGGTGATGAAGGT\n" +
    "CAAACATCCAGGCTCTTGCTAAGCAGCTAGACGGGGGGGTATTCCGGTCG\n" +
    "TGATAACGAAT\n"

export default exampleQuery;
